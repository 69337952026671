import React from 'react'
import NavBar from './NavBar'

const Header = () => {
  return (
    <div>
      <NavBar/>
    </div>
  )
}

export default Header
