import React from 'react'

const AI = () => {
  return (
    <div className='p-14 md-mx:p-8  sm-mx:p-6  xs-mx:p-2 xsm-mx:p-1'>
      <img className='' src="/Image/AI.png" alt="" />
    </div>
  )
}

export default AI
